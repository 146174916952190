<template>
  <div>
    <div class="greyBackground"></div>
    <v-card class="greyColor" elevation="0">
      <h2 class="faledia-caption">Bündeln und Entbündeln</h2>
      <v-card outlined>
        <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
        <v-card-text>
          Die Kinder können...
          <ul>
            <li>
              ungebündelte Materialsätze so lange bündeln, bis keine weitere
              Bündelung mehr möglich ist.
            </li>
            <li>
              gebündelte Materialsätze so lange entbündeln, bis keine weitere
              Entbündelung mehr möglich ist.
            </li>
            <li>
              Bündelungen und Entbündelungen mit verschiedenen didaktischen
              Materialien verständnisbasiert vornehmen.
            </li>
          </ul>
        </v-card-text>
      </v-card>
      <br />
      <h3 class="faledia-caption">
        Was sollten Sie zu Bündeln und Entbündeln wissen?
      </h3>
      <p>
        Zahlen werden heute im Stellenwertsystem zur Basis 10 dargestellt – das
        war nicht immer so, denken Sie z. B. an die römische Zahlschreibweise.
        Allen Stellenwertsystemen liegt dabei grundsätzlich das Prinzip der
        fortgesetzten Bündelung zugrunde (vgl. Padberg & Benz 2011). Dieses
        besagt, dass Objekte so lange zu Zehnerbündeln und damit Einheiten
        nächsthöherer Ordnung zusammengefasst werden, bis kein neues
        Zehnerbündel mehr voll wird. Anschaulich wird dies in der Grundschule
        häufig an Zehnersystemblöcken verdeutlicht: Zehn Einerwürfel werden in
        den nächsthöheren Stellenwert gebündelt, also zu einer Zehnerstange,
        zehn Zehnerstangen zu einer Hunderterplatte und so weiter. Jede Menge
        kann durch maximales fortgesetztes Bündeln eindeutig und schnell
        erkennbar dargestellt werden (vgl. Padberg & Benz 2011), wie im
        folgenden Beispiel (in Anlehnung an Schulz, 2015):
      </p>

      <v-simple-table class="imageTable">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                <div class="imageTableImages" style="width: 150px">
                  <AppExpandableImage
                    contain
                    :src="require('@/assets/swve/swve_einer.png')"
                    parentId="swve"
                    elementId="swve_einer.png"
                  ></AppExpandableImage>
                </div>
                <i>Abb. Einerwürfel</i>
              </td>
              <td>
                Bei dieser Anzahl von Einerwürfeln ist auf einen Blick nicht
                erkennbar, um wie viele Würfel es sich handelt.
              </td>
            </tr>
            <tr>
              <td>
                <div class="imageTableImages" style="width: 200px">
                  <AppExpandableImage
                    contain
                    :src="require('@/assets/swve/swve_zehner.png')"
                    parentId="swve"
                    elementId="swve_zehner.png"
                  ></AppExpandableImage>
                </div>
                <i>Abb. Bündeln zu Zehnerstangen</i>
              </td>
              <td>
                Ein erster Schritt zu mehr Übersichtlichkeit kann die
                Strukturierung in Zehnerbündel (Umtausch in Zehnerstangen) sein.
              </td>
            </tr>
            <tr>
              <td>
                <div class="imageTableImages" style="width: 200px">
                  <AppExpandableImage
                    contain
                    :src="require('@/assets/swve/swve_voll_buendelung.png')"
                    parentId="swve"
                    elementId="swve_voll_buendelung.png"
                  ></AppExpandableImage>
                </div>
                <i>Abb. Vollständige Bündelung</i>
              </td>
              <td>
                Bündelt man weiter, so ist die Anzahl der Würfel für uns mit
                einem Blick erkennbar.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p>
        Das von der konkreten Anschauung abgelöste Prinzip ist weiterführend
        auch für das Verständnis von Dezimalzahlen von zentraler Bedeutung. So
        kann man sich vorstellen, dass auch Einer entbündelt werden können,
        wodurch Zehntel und durch weiteres Entbündeln Hundertstel usw.
        entstehen.
      </p>

      <div class="center">
        <AppExpandableImage
          contain
          :src="require('@/assets/swve/swve_fort_buendeln.png')"
          parentId="swve"
          elementId="swve_fort_buendeln.png"
        >
        </AppExpandableImage>
        <i>Abb. fortgesetztes Bündeln und Entbündeln im Dezimalzahlbereich</i>
      </div>

      <br />

      <p>
        In unserem üblichen dekadischen System sind wir es gewohnt zu bündeln,
        zu entbündeln und können leicht angeben, wie bei einer sukzessiven
        Erhöhung einer Zahl um einen bestimmten Wert die nächste Zahl aussehen
        muss. Dies müssen die Kinder in der Grundschule allerdings erst noch
        lernen. Um sich noch einmal in die Situation hineinversetzen zu können,
        wie es ist ein Stellenwertsystem noch nicht (vollständig) durchdrungen
        und verinnerlicht zu haben, können Sie sich das folgende Video zum
        Weiterzählen im Vierersystem anschauen.
      </p>

      <p>
        In unseren Beispielen herrscht Bündelungszwang. Grundsätzlich ist es
        aber nicht falsch, wenn Kinder Anzahlen, die die Bündelungseinheit
        überschreiten, in einer Stellenwerttafel notieren. Erst bei der
        Übersetzung in die Normalschreibweise, also die Darstellung der Zahl
        mittels Zahlsymbolen, muss vollständig gebündelt werden.
      </p>

      <video
        class="lbst-border"
        preload="auto"
        controls
        width="60%"
        style="margin-left: 20%"
        @play="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @fullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @webkitfullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @mozfullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @msfullscreenchange="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @ended="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @pause="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @seeking="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @enterpictureinpicture="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
        @leavepictureinpicture="
          videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
        "
      >
        <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
        <source
          src="@/assets/swvi/4er-System um 1 weiterzählen_201103_YB.mp4"
          type="video/mp4"
        />
      </video>

      <p>
        Vielleicht haben Sie festgestellt, dass es Ihnen im Vierersystem etwas
        schwerer fällt schnell vorauszusagen, wie die nächstgrößere Zahl
        aussieht. Ebenso kann es für Kinder, die noch unsicher im Umgang mit dem
        Zehnersystem sind, durchaus eine anspruchsvolle Aufgabe sein eine
        sukzessive Erhöhung von Zahlen durch das Hinzufügen von jeweils einem
        Plättchen in einer Stellenwerttafel nachzuvollziehen.
      </p>

      <p>
        Noch fordernder wird dies, wenn die zu verändernden Zahlen größer sind
        und zudem nicht nur um eins, sondern um einen anderen Wert erhöht
        werden. Um sich auch dies zu vergegenwärtigen, sind Sie aufgefordert im
        nachfolgenden Beispiel die Zahl (230)<sub>5</sub> sukzessive immer genau
        um zwei Einer zu erhöhen.
      </p>
      <div style="background-color: white">
        <LernbausteinSchieberegler
          :id="LBSTSchieberegler[0].id"
          :workOrder="LBSTSchieberegler[0].workOrder"
          :originalSlideWidth="LBSTSchieberegler[0].originalSlideWidth"
          :originalSlideHeight="LBSTSchieberegler[0].originalSlideHeight"
          :slides="LBSTSchieberegler[0].slides"
        >
          <template v-slot:alternative-content>
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @fullscreenchange="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @webkitfullscreenchange="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @mozfullscreenchange="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @msfullscreenchange="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @ended="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @pause="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @seeking="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @enterpictureinpicture="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
              @leavepictureinpicture="
                videoPlayerEvent(
                  $event,
                  '5er-System um 2 weiterzählen_201103_YB.mp4'
                )
              "
            >
              <source
                src="@/assets/swvi/5er-System um 2 weiterzählen_201103_YB.mp4"
                type="video/mp4"
              />
            </video>
          </template>
        </LernbausteinSchieberegler>
      </div>
      <p>
        Vermutlich ist Ihnen aufgefallen, dass bei der Betrachtung größerer
        Zahlen und der Veränderung der Zahlen um den Wert zwei gleich mehrere
        Dinge zu beachten sind:
      </p>

      <ol>
        <li>Ist eine Bündelung erforderlich?</li>
        <li>
          Verbleiben nach einer Bündelung noch Plättchen in der Spalte, in der
          eine Bündelung erforderlich wurde?
        </li>
        <li>
          Ist in der Spalte, in welcher die Plättchen durch die Bündelung
          überführt wurden, durch das Hinzukommen der gebündelten Plättchen
          gegebenenfalls eine weitere Bündelung erforderlich?
        </li>
      </ol>
      <br />
      <p>
        Für Kinder, die im Stellenwertsystem zur Basis 10 noch nicht sicher
        agieren können, stellen diese vielfältigen Anforderungen eine große
        Herausforderung dar, wenn sie im Zehnersystem ähnliche Aufgaben lösen.
        Sollen sie beispielsweise eine Zahl mit Plättchen in der
        Stellenwerttafel um zwölf erhöhen, müssen sie erkennen, dass dies
        bedeutet, dass dazu ein Plättchen in der Zehnerspalte und zwei in der
        Einerspalte hinzugelegt werden müssen. Dies zu erkennen ist eine
        Herausforderung für die Kinder, welche aber zugleich einen Grundstein
        für eine verstehensbasierte Durchführung der Addition und Subtraktion
        von Zahlen darstellt.
      </p>

      <p>
        Das Nachvollziehen von Handlungen in anderen und ungewohnten
        Stellenwertsystemen kann Ihnen erfahrbar machen, wie anspruchsvoll das
        Erlernen der Systematik unseres Stellenwertsystems und des Umgangs mit
        Zahlen in eben diesem für Kinder sein muss. Wo für uns bereits
        automatisiert ist, wann gebündelt werden muss und welche Auswirkungen
        das hat, müssen Kinder immer wieder bewusst und aktiv überlegen.
      </p>

      <AppButtonCollapse>
        <div style="background-color: white">
          <LernbausteinSchieberegler
            :id="LBSTSchieberegler[1].id"
            :workOrder="LBSTSchieberegler[1].workOrder"
            :originalSlideWidth="LBSTSchieberegler[1].originalSlideWidth"
            :originalSlideHeight="LBSTSchieberegler[1].originalSlideHeight"
            :slides="LBSTSchieberegler[1].slides"
          >
            <template v-slot:alternative-content>
              <video
                preload="auto"
                controls
                width="60%"
                style="margin-left: 20%"
                @play="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @fullscreenchange="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @webkitfullscreenchange="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @mozfullscreenchange="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @msfullscreenchange="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @ended="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @pause="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @seeking="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @enterpictureinpicture="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
                @leavepictureinpicture="
                  videoPlayerEvent(
                    $event,
                    '3er-System um 4 weiterzählen_201103_YB.mp4'
                  )
                "
              >
                <source
                  src="@/assets/swvi/3er-System um 4 weiterzählen_201103_YB.mp4"
                  type="video/mp4"
                />
              </video>
            </template>
          </LernbausteinSchieberegler>
        </div>
      </AppButtonCollapse>

      <p>
        Ähnlich wie beim Beispiel zum Fünfersystem müssen hier viele Dinge
        berücksichtigt werden. Da hier gleichzeitig in zwei Spalten jeweils ein
        Plättchen hinzugefügt wurde, mussten Sie dabei jedoch immer direkt
        mindestens zwei Spalten im Blick behalten.
      </p>

      <br />

      <AppLiteraturSWV />
    </v-card>
    <AppBottomNavSWV
      back="/stellenwertverstaendnis/hintergrundwissen"
      next="/stellenwertverstaendnis/hintergrundwissen/stellenwert-und-zahlenwert"
    />
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppLiteraturSWV from "@/common/AppLiteraturSWV";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppBottomNavSWV from "@/common/AppBottomNavSWV";
import AppButtonCollapse from "@/common/AppButtonCollapse";

import LernbausteinSchieberegler from "@/components/faledia/explorativeElemente/LernbausteinSchieberegler";
import LernbausteinSchieberegler_2_SEBH_1_5er_System from "@/components/faledia/seiten/swve/beispiele/2_SEBH_1_5er_System";
import LernbausteinSchieberegler_3_SEBH_1_3er_System from "@/components/faledia/seiten/swve/beispiele/3_SEBH_1_3er_System";

export default {
  components: {
    AppLiteraturSWV,
    AppBottomNavSWV,
    AppExpandableImage,
    AppButtonCollapse,
    LernbausteinSchieberegler,
  },
  data: () => ({
    LBSTSchieberegler: [
      LernbausteinSchieberegler_2_SEBH_1_5er_System,
      LernbausteinSchieberegler_3_SEBH_1_3er_System,
    ],
  }),
  methods: {
    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>

<style scoped>
#app .imageTableImages {
  margin-top: 40px;
}
#app .imageTable {
  margin-bottom: 20px;
}
#app .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
</style>
