import { render, staticRenderFns } from "./BuendelnUndEntbuendeln.vue?vue&type=template&id=01c305d4&scoped=true&"
import script from "./BuendelnUndEntbuendeln.vue?vue&type=script&lang=js&"
export * from "./BuendelnUndEntbuendeln.vue?vue&type=script&lang=js&"
import style0 from "./BuendelnUndEntbuendeln.vue?vue&type=style&index=0&id=01c305d4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c305d4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VCardTitle,VSimpleTable})
